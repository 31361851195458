import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AddGroupAssigneesPanelViewProps } from './AddGroupAssigneesPanelView.types';
import { ButtonSecondaryOutlined, ButtonTertiary } from 'components/lib/Button';
import { BUTTON_CANCEL_TESTID, BUTTON_SUBMIT_TESTID } from 'utils/testIds';
import { PlusIcon } from 'components/Icon';
import { useAddGroupAssigneesPanelViewStyles } from './AddGroupAssigneesPanelView.styles';
import List from 'components/List';
import { AvatarItem } from 'components/lib/Avatar/types';
import GroupListElement from 'pages/UserGroups/components/Permissions/GroupListElement';
import AutocompleteUserGroupsSelect from 'components/AutocompleteUsersGroupsSelect';
import { OptionGroupAvatar } from 'components/OptionAvatars/OptionGroupAvatar';
import { useBeforeunload } from 'react-beforeunload';
import TooltipString from 'components/TooltipString';
import CancelConfigurationModal from 'components/CancelConfigurationModal';
import CustomPrompt from 'components/CustomPrompt';
import { CustomPromptType } from 'components/CustomPrompt/types';
import {
  useAddGroupAssignees,
  useAddGroupAssigneesCancellation,
  useGroupAssigneeesLimit,
  useGroupAssigneesSelection,
} from './hooks';
import { Alert } from 'components/lib/Alert';

/**
 * It's a component dedicated to use in panels that allows assiging groups for a given resource
 * e.g. object class permission set.
 *
 * @param panelKey Indicates which panel is a parent of this component.
 * @param resourceName Name of a resource for which groups are being assigned e.g. permission set name.
 * @param assignedGroupIds List of group ids that are already assigned to the resource.
 * @param autocompleteEndpoint Endpoint called by autocomplete select.
 * @param postEndpoint Endpoint called on form submission.
 * @param groupsLimit Limit of groups that can be assigned to a resource.
 * @param fetchGroupAssignees Function that fetches groups assigned to the resource.
 */
export const AddGroupAssigneesPanelView = ({
  panelKey,
  resourceName,
  assignedGroupIds,
  autocompleteEndpoint,
  postEndpoint,
  groupsLimit,
  fetchGroupAssignees,
}: AddGroupAssigneesPanelViewProps) => {
  const intl = useIntl();

  const styles = useAddGroupAssigneesPanelViewStyles();

  const {
    selectedGroups,
    hasSelectedGroups,
    getIsGroupDisabled,
    getDisabledGroupText,
    handleGroupSelect,
    handleGroupRemove,
  } = useGroupAssigneesSelection(assignedGroupIds);
  const {
    showUnsavedChangesModal,
    navigateTo,
    handlePanelCancelBtn,
    handleCustomPromptCancelBtn,
    handleUnsavedChangesModalConfirmBtn,
    handleUnsavedChangesModalCancelBtn,
  } = useAddGroupAssigneesCancellation(panelKey, hasSelectedGroups);
  const { isSubmitting, handleSubmit } = useAddGroupAssignees(
    panelKey,
    selectedGroups,
    postEndpoint,
    resourceName,
    groupsLimit,
    fetchGroupAssignees
  );
  const { isLimitReached, limitReachedText } = useGroupAssigneeesLimit(
    groupsLimit,
    selectedGroups.length,
    assignedGroupIds.length
  );

  const title = intl.formatMessage(
    {
      id: 'misc.assignUserGroupsTo',
      defaultMessage: 'Assign user groups to {name}',
    },
    { name: resourceName }
  );
  const emptyDataDescription = intl.formatMessage({
    id: 'misc.noUserGroupsSelected',
    defaultMessage: 'No user groups selected',
  });
  const selectPlaceholder = intl.formatMessage({
    id: 'placeholders.searchForUserGroups',
    defaultMessage: 'Search for user groups',
  });

  // Prevent e.g. refreshing or closing the page when user has selected some groups, i.e. the form is dirty
  useBeforeunload(event => hasSelectedGroups && event.preventDefault());

  return (
    <div className={styles.mainWrapper}>
      <header className={styles.header}>
        <h3 className={styles.title}>
          <TooltipString text={title} />
        </h3>

        <p className={styles.counterText}>
          <FormattedMessage
            id='misc.groupsSelected'
            defaultMessage='{count, plural, one {# user group selected} other {# user groups selected}}'
            values={{ count: selectedGroups.length }}
          />
        </p>
      </header>

      <div className={styles.contentWrapper}>
        {isLimitReached ? (
          <Alert type='error' message={limitReachedText} />
        ) : (
          <AutocompleteUserGroupsSelect
            placeholder={selectPlaceholder}
            autocompleteUrl={autocompleteEndpoint}
            selectMultiple
            onChange={handleGroupSelect}
            checkOptionIsDisabled={getIsGroupDisabled}
            renderOption={(option, searchValue) => (
              <OptionGroupAvatar
                option={option}
                searchValue={searchValue}
                disabledText={getDisabledGroupText(option.id)}
                showUserCount
              />
            )}
          />
        )}

        <List<AvatarItem>
          items={selectedGroups}
          emptyDataDescription={emptyDataDescription}
          renderItem={item => (
            <GroupListElement
              {...item}
              onDelete={() => handleGroupRemove(item.id)}
            />
          )}
        />
      </div>

      <footer className={styles.footer}>
        <ButtonSecondaryOutlined
          data-testid={BUTTON_CANCEL_TESTID}
          onClick={handlePanelCancelBtn}
        >
          <FormattedMessage id='misc.cancel' defaultMessage='Cancel' />
        </ButtonSecondaryOutlined>

        <ButtonTertiary
          icon={<PlusIcon size={10} />}
          loading={isSubmitting}
          disabled={!hasSelectedGroups}
          data-testid={BUTTON_SUBMIT_TESTID}
          onClick={handleSubmit}
        >
          <FormattedMessage id='misc.add' defaultMessage='Add' />
        </ButtonTertiary>
      </footer>

      <CancelConfigurationModal
        visible={showUnsavedChangesModal}
        onCancel={handleUnsavedChangesModalCancelBtn}
        onConfirm={handleUnsavedChangesModalConfirmBtn}
      />
      <CustomPrompt
        when={!navigateTo && hasSelectedGroups}
        type={CustomPromptType.CANCEL}
        onCancel={handleCustomPromptCancelBtn}
      />
    </div>
  );
};
