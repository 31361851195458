import { useIntl } from 'react-intl';
import { UsersOrGroupsTabKey } from 'utils/Enums/UsersOrGroupsTabKey';

export const useReassignTaskTabs = (userAssigneesCount: number) => {
  const intl = useIntl();

  const usersTabText = intl.formatMessage(
    {
      id: 'misc.usersTab',
      defaultMessage: 'Users ({count})',
    },
    { count: userAssigneesCount }
  );

  return {
    activeTab: UsersOrGroupsTabKey.Users,
    usersTab: {
      key: UsersOrGroupsTabKey.Users,
      text: usersTabText,
    },
  };
};
