import React, {
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useState,
  forwardRef,
  memo,
} from 'react';
import {
  useTable,
  useSortBy,
  usePagination,
  useResizeColumns,
  useColumnOrder,
  TableState,
  useBlockLayout,
} from 'react-table';
import { Loader } from 'components/lib/Loader';
import EmptyData from 'components/EmptyData';
import useDefaultColumns from 'hooks/useDefaultColumns';
import {
  DEFAULT_COLUMNS_WIDTHS,
  ACTIONS_COLUMN_WIDTH,
  EMPTY_TABLE_HEIGHT,
} from './Table.consts';
import useTableWrapperStyles, {
  useTableBodyStyles,
  useTableStyles,
} from './Table.styles';
import clsx from 'clsx';
import useEmptyTableMessage from 'hooks/useEmptyTableMessage';
import { useTableContext } from 'contexts/TableContext';
import { useTableRowContext } from 'contexts/TableRowContext';
import PaginationWrapper from './components/PaginationWrapper';
import { useContentWrapperContext } from 'contexts/ContentWrapperContext';
import useAppliedFilters from 'hooks/useAppliedFilters';
import { useMenuSidebarWidth } from 'components/PageLayout/hooks';
import { TableRow } from './components/TableRow';
import { FixedSizeList, ListProps } from 'react-window';
import useDensity from 'hooks/useDensity';
import TableModals from './components/TableModals';
import { useStateReducer } from './hooks/useStateReducer';
import { useConfirmationModals } from './hooks/useConfirmationModals';
import { useScrollbars } from './hooks/useScrollbars';
import { useTableHasChanges } from './hooks/useTableHasChanges';
import { useDispatch, useSelector } from 'react-redux';
import {
  TABLE_BODY_ID,
  TABLE_HEAD_ID,
  TABLE_PAGINATION_ID,
  TABLE_WRAPPER,
} from 'utils/elementsIds';
import { RowsDisplay } from './Table.types';
import { TABLE_BODY_TESTID, TABLE_EMPTY_COLUMN_TESTID } from 'utils/testIds';
import useEmptyTableDescription from '../../hooks/useEmptyTableDescription';
import { useBeforeunload } from 'react-beforeunload';
import isEqual from 'lodash/isEqual';
import {
  composeResizingColumnWidths,
  calculateAdditionalColumnWidth,
  calculateFixedSizeListHeight,
} from './utils';
import { useColumnStyles } from './hooks/useColumnStyles';
import omit from 'lodash/omit';
import EmptyTableRow from './components/EmptyTableRow';
import {
  getObjectRecordsInitialColumns,
  getObjectRecordsSelectedColumns,
} from 'store/selectors/objectRecordsSelectors';
import { useHasVerticalScroll } from './hooks/useHasVerticalScroll';
import { TableHeaderGroups } from './components/TableHeaderGroups';
import { TableHeadersContextProvider } from './contexts/TableHeadersContextProvider';

const TableComponent: React.FC = () => {
  const {
    columns,
    data,
    isFetching: loading,
    sortParams = { id: 'id', desc: true },
    onChangeSort,
    initialState,
    editModeEnabled: editMode,
    currentTableName,
    currentPage,
    isInfinite,
    pageSize,
    withPagination = true,
    searchValue,
    getEmptyMessageDescription,
    isVisibleBulkSelectionColumn,
    hasListPermissions,
    filterSelectOptions,
    withFilters,
    filteredCount,
    rowsDisplay,
    columnsConfigurationLoading,
    preventOverflow,
    withBordersAroundTable,
    fullWidth,
    renderEmptyTable,
    renderHeaderLimit,
    customTableId,
    setIsVisibleBulkSelectionColumn,
  } = useTableContext();
  const { density, setSelectedRow, onActionCellClick } = useTableRowContext();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      if (setSelectedRow) {
        dispatch(setSelectedRow(undefined));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialRecordsCols = useSelector(getObjectRecordsInitialColumns);
  const currentRecordsCols = useSelector(getObjectRecordsSelectedColumns);

  const cellHeight = useDensity(density);
  const emptyTableMessage = useEmptyTableMessage(currentTableName);
  const appliedFilters = useAppliedFilters();
  const emptyTableDescription = useEmptyTableDescription(
    currentTableName,
    hasListPermissions,
    appliedFilters
  );
  const sidebarWidth = useMenuSidebarWidth();
  const defaultTableColumns = useDefaultColumns(currentTableName);

  const [initialLoad, setInitialLoad] = useState(false);

  const [temporaryState, setTemporaryState] = useState<
    TableState<object> | undefined
  >();

  const defaultColumn = useMemo(() => DEFAULT_COLUMNS_WIDTHS, []);
  const { stateReducer, setConfirmedCancel } = useStateReducer({
    temporaryState,
  });

  const hasScrollY = useHasVerticalScroll();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setColumnOrder,
    setHiddenColumns,
    visibleColumns,
    resetResizing,
    totalColumnsWidth,
  } = useTable(
    {
      columns,
      data,
      manualSortBy: true,
      initialState: {
        ...initialState,
        sortBy: [sortParams],
      },
      autoResetHiddenColumns: false,
      defaultColumn,
      disableSortRemove: true,
      stateReducer,
    },
    useSortBy,
    usePagination,
    useResizeColumns,
    useBlockLayout,
    useColumnOrder
  );
  const { hiddenColumns, columnResizing, columnOrder } = state;

  useEffect(() => {
    if (editMode) return;

    const isSyncInitialColumnOrderWithState = isEqual(
      initialState?.columnOrder,
      columnOrder
    );
    const isSyncInitialHiddenColumnsWithState = isEqual(
      initialState?.hiddenColumns,
      hiddenColumns
    );

    if (!isSyncInitialColumnOrderWithState && initialState?.columnOrder)
      setColumnOrder(initialState?.columnOrder);

    if (!isSyncInitialHiddenColumnsWithState && initialState?.hiddenColumns)
      setHiddenColumns(initialState?.hiddenColumns);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState]);

  const {
    setShouldShowConfirmation,
    setCancelConfigModalVisible,
    ...modalsActions
  } = useConfirmationModals({
    setConfirmedCancel,
    temporaryState,
    setColumnOrder,
  });
  const hasChanges = useTableHasChanges(
    state,
    temporaryState,
    initialRecordsCols,
    currentRecordsCols
  );

  const { disabledEdition } = useContentWrapperContext();

  const emptyTable = useMemo(
    () =>
      (!loading && !columnsConfigurationLoading && data.length === 0) ||
      !hasListPermissions,
    [columnsConfigurationLoading, data.length, hasListPermissions, loading]
  );

  const tableBodyRef = useRef<null | DOMRect>(null);
  const listRef = useRef<FixedSizeList | null>(null);

  const isInitialLoading = useMemo(
    () => loading && columnsConfigurationLoading && !data.length,
    [data, loading, columnsConfigurationLoading]
  );

  const classes = useTableStyles({
    emptyTable,
    preventOverflow,
    withBordersAroundTable,
    isInitialLoading,
    density,
    hasScrollY,
    fullWidth,
  });

  const { tableBody } = useTableBodyStyles({
    tableBodyTopOffset: tableBodyRef?.current?.top,
    addColumnMode: disabledEdition,
    isInitialLoading,
    isNoData: !data.length,
    preventOverflow,
  });

  const tableClasses = useTableWrapperStyles({
    ACTIONS_COLUMN_WIDTH,
    emptyTable: {
      sidebarWidth,
      totalColumnsWidth,
      ignoreTotalColumnsWidth: columns.length === 0,
    },
  });

  useBeforeunload(event => {
    if (editMode && hasChanges) {
      event.preventDefault();
    }
  });

  useEffect(() => {
    setTemporaryState(editMode ? state : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  const emptyTableWidth = fullWidth ? '100%' : 'var(--table-row-width)';
  const customEmptyTable = renderEmptyTable?.({
    tableWidth: emptyTableWidth,
  });

  useEffect(() => {
    if (!initialLoad) {
      setInitialLoad(true);
    }
  }, [initialLoad]);

  useEffect(() => {
    if (initialLoad) {
      const { id, desc = false } = state.sortBy[0];

      onChangeSort({ id, desc });
    }

    if (!!listRef.current) {
      listRef.current.scrollTo(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.sortBy]);
  // do not add initialLoad to dependencies, otherwise you'll break the loading currentPage number from query params

  useEffect(() => {
    if (!!listRef.current && !isInfinite) {
      listRef.current.scrollTo(0);
    }
  }, [currentPage, isInfinite, pageSize]);

  useEffect(() => {
    // scroll to the top after calling "onChange" action in <FilterSelect /> which uses "filterSelectOptions" to update "searchValue"
    if (!!listRef.current && !!filterSelectOptions) listRef.current.scrollTo(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue?.value]);

  useEffect(() => {
    const { isVisibleBulkSelection } =
      filterSelectOptions?.find(
        option => option.value === searchValue?.value
      ) || {};
    setIsVisibleBulkSelectionColumn(!!isVisibleBulkSelection as boolean);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSelectOptions, setIsVisibleBulkSelectionColumn]);

  useEffect(() => {
    if (tableBodyRef.current === null || (tableBodyRef.current && density)) {
      const sidebarElement =
        window.document
          .getElementById(TABLE_BODY_ID + customTableId)
          ?.getBoundingClientRect() || null;

      tableBodyRef.current = sidebarElement;
    }
  }, [density, data.length, customTableId]);

  const itemCount = useMemo(() => {
    if (rowsDisplay !== RowsDisplay.FIXED) {
      return rows.length;
    }

    return filteredCount - pageSize * currentPage < pageSize
      ? pageSize
      : rows.length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredCount, pageSize, rows.length, rowsDisplay]); //currentPage wasn't included in deps array, because with it there is one render which is out of sync - currentPage shows new value, but rows haven't updated yet to reflect correct number for this page

  const emptyDataTitle = appliedFilters ? emptyTableMessage : '';

  const RenderRow = useCallback(
    ({ index, style }) => {
      const row = rows[index];

      if (!row && rowsDisplay === RowsDisplay.FIXED) {
        return <EmptyTableRow index={index as number} />;
      }
      prepareRow(row);

      return (
        <TableRow
          {...{
            row,
            totalColumnsWidth,
            currentTableName,
            style,
            index,
          }}
        />
      );
    },
    [currentTableName, prepareRow, rows, totalColumnsWidth, rowsDisplay]
  );

  const { onBodyScroll, onHeaderScroll } = useScrollbars({
    appliedFilters,
    customTableId,
  });

  const onTableBodyScroll = useCallback(
    (event, virtualizedScrollMethod) => {
      virtualizedScrollMethod(event);
      onBodyScroll(event);
      onHeaderScroll(event);
    },
    [onBodyScroll, onHeaderScroll]
  );

  const onTableHeaderScroll = useCallback(
    event => {
      onBodyScroll(event);
      onHeaderScroll(event);
    },
    [onBodyScroll, onHeaderScroll]
  );

  const Outer = useMemo(
    () =>
      forwardRef<HTMLDivElement, ListProps>((props, ref) => {
        const { onScroll, style } = props;
        const restStyles = omit(style || {}, ['width', 'height']);

        return (
          <div
            {...{ ref, ...props }}
            {...getTableBodyProps({
              style: {
                ...restStyles,
                overflow: preventOverflow ? 'none' : restStyles.overflow,
              },
            })}
            className={tableBody}
            id={TABLE_BODY_ID + customTableId}
            onScroll={event => onTableBodyScroll(event, onScroll)}
          />
        );
      }),
    //please, do not add any other dependencies here if not necessary, especially getTableBodyProps
    // otherwise you'll spend hours trying to fix table virtualization
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onTableBodyScroll, isVisibleBulkSelectionColumn]
  );

  const additionalColumnWidth = calculateAdditionalColumnWidth(
    isVisibleBulkSelectionColumn,
    onActionCellClick !== undefined
  );

  const sizes = useColumnStyles(headerGroups, additionalColumnWidth);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const fixedSizeListHeight = calculateFixedSizeListHeight(
    tableBodyRef,
    preventOverflow,
    pageSize,
    cellHeight
  );

  return (
    <>
      <TableModals
        {...{
          state,
          ...modalsActions,
          defaultTableColumns,
          setHiddenColumns,
          setColumnOrder,
          hasChanges,
          resetResizing,
          setShouldShowConfirmation,
          setCancelConfigModalVisible,
        }}
      />
      <div className={classes.tablePagination} id={TABLE_PAGINATION_ID}>
        {withPagination && (
          <PaginationWrapper
            tableState={{
              ...state,
              columnResizing: composeResizingColumnWidths(
                columnResizing,
                initialState?.columnResizing
              ),
            }}
            {...{
              setCancelConfigModalVisible,
              setShouldShowConfirmation,
              listRef,
              hasChanges,
            }}
          />
        )}
      </div>
      {renderHeaderLimit?.()}
      <div
        className={classes.tableContainer}
        ref={wrapperRef}
        style={sizes}
        id={TABLE_WRAPPER}
      >
        <Loader
          spinning={loading || columnsConfigurationLoading}
          className={classes.loader}
        >
          <span
            className={clsx({
              [classes.tableWithHeaderWrapper]: withBordersAroundTable,
            })}
          >
            <div
              className={clsx({ [classes.leftBorder]: withBordersAroundTable })}
            />
            <div
              className={clsx({
                [classes.tableWithHeaderContainer]: withBordersAroundTable,
              })}
            >
              <div
                {...getTableProps()}
                className={clsx([
                  classes.table,
                  classes.tableHeaderWrapper,
                  { [classes.noScrollbar]: !emptyTable },
                ])}
                id={TABLE_HEAD_ID + customTableId}
                style={{
                  height:
                    emptyTable && !customEmptyTable
                      ? EMPTY_TABLE_HEIGHT
                      : 'auto',
                }}
                onScroll={onTableHeaderScroll}
              >
                <TableHeadersContextProvider
                  visibleColumns={visibleColumns}
                  rows={rows}
                  totalColumnsWidth={totalColumnsWidth}
                  hiddenColumns={hiddenColumns}
                  headerGroups={headerGroups}
                  isEditMode={editMode}
                  setColumnOrder={setColumnOrder}
                  setHiddenColumns={setHiddenColumns}
                  columnResizing={columnResizing}
                  headerWrapperRef={wrapperRef}
                >
                  <TableHeaderGroups />
                </TableHeadersContextProvider>
                {emptyTable &&
                  (customEmptyTable ? (
                    customEmptyTable
                  ) : (
                    <div
                      className={tableClasses.emptyTableWrapper}
                      data-testid={TABLE_EMPTY_COLUMN_TESTID}
                    >
                      <EmptyData
                        title={emptyDataTitle}
                        {...{ hasListPermissions }}
                        description={
                          getEmptyMessageDescription?.(
                            emptyTableDescription,
                            searchValue,
                            appliedFilters && withFilters
                          ) || emptyTableDescription
                        }
                      />
                    </div>
                  ))}
              </div>
              <div
                {...getTableProps()}
                className={clsx(classes.table, classes.filledTable)}
                data-testid={TABLE_BODY_TESTID}
              >
                <FixedSizeList
                  itemCount={itemCount}
                  itemSize={cellHeight}
                  height={fixedSizeListHeight}
                  width={totalColumnsWidth}
                  outerElementType={Outer}
                  ref={listRef}
                >
                  {RenderRow}
                </FixedSizeList>
              </div>
            </div>
            <div
              className={clsx({
                [classes.rightBorder]: withBordersAroundTable,
              })}
            />
          </span>
        </Loader>
      </div>
    </>
  );
};

export const Table = memo(TableComponent);
