import React from 'react';
import { UnassignTaskUserAssigneeModalProps } from './UnassignTaskUserAssigneeModal.types';
import ConfirmationModal from 'components/ConfirmationModal';
import { useIntl } from 'react-intl';
import WarningIllustration from 'img/illustrations/warning-triangle.svg';

export const UnassignTaskUserAssigneeModal = ({
  userToUnassign,
  isUnassigningHimself,
  isLoading,
  onCancel,
  onConfirm,
}: UnassignTaskUserAssigneeModalProps) => {
  const intl = useIntl();

  const title = isUnassigningHimself
    ? intl.formatMessage({
        id: 'misc.sureToUnassignYourself',
        defaultMessage: 'Are you sure you want to unassign yourself?',
      })
    : intl.formatMessage(
        {
          id: 'misc.sureToUnassign',
          defaultMessage: 'Are you sure you want to unassign {name}?',
        },
        {
          name: userToUnassign?.name,
        }
      );
  const subtitle = isUnassigningHimself
    ? intl.formatMessage({
        id: 'tasksPanel.youMayLoseAccessToTask',
        defaultMessage: 'You may lose access to this task.',
      })
    : intl.formatMessage({
        id: 'tasksPanel.theyMayLoseAccessToTask',
        defaultMessage: 'They may lose access to this task.',
      });
  const confirmBtnText = intl.formatMessage({
    id: 'misc.yesUnassign',
    defaultMessage: 'Yes, unassign',
  });

  return (
    <ConfirmationModal
      visible={!!userToUnassign}
      isLoading={isLoading}
      title={title}
      subtitle={subtitle}
      confirmationButtonLabel={confirmBtnText}
      image={WarningIllustration}
      withCancel
      onClose={onCancel}
      onConfirm={onConfirm}
    />
  );
};
