import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { CustomAvatarGroup } from 'components/lib/Avatar';
import { UsersGroupsSelector } from 'components/UsersAndGroupsSelection/UsersGroupsSelector';
import { Select as AntSelect } from 'antd';
import { useScrollableParent } from 'hooks/useScrollableParent';
import { UsersAndGroupsExpandablePicker } from 'components/UsersAndGroupsSelection/UsersAndGroupsExpandableSelect/components/UsersAndGroupsExpandablePicker';
import { useUsersAndGroupsExpandableSelectStyles } from './UsersAndGroupsExpandableSelect.styles';
import {
  UsersAndGroupsExpandablePickerRef,
  UsersAndGroupsExpandableSelectProps,
} from './types';
import {
  RECORD_LISTING_WRAPPER,
  USERS_AND_GROUPS_EXPANDABLE_SELECT_AVATARS_WRAPPER,
} from 'utils/testIds';
import clsx from 'clsx';
import { UsersAndGroupsExpandablePickerContextProvider } from './contexts/UsersAndGroupsExpandablePickerContext';
import { useSelectOptionAvatars } from 'hooks/avatars/useSelectOptionAvatars';

/**
 * Allows selection of users and groups from a select dropdown and expandable group list. This can work in two modes:
 * 1. Simple picker that will apply changes immediately up the component chain.
 * 2. Edit mode picker that allows you to react to the user intention of closing the dropdown or rejecting what he did with an additional reject button.
 * To use the edit mode, provide {@link UsersAndGroupsExpandableSelectProps.editModeOptions} prop.
 */
export const UsersAndGroupsExpandableSelect = forwardRef<
  UsersAndGroupsExpandablePickerRef,
  UsersAndGroupsExpandableSelectProps
>(
  (
    {
      isLoadingOptions,
      options,
      selection,
      setSelection,
      isFieldDisabled,
      withUnderline = true,
      errors,
      limits,
      reValidateField,
      onDropdownOpenChange,
      editModeOptions,
      className,
      interactableAvatars = false,
      onBlur,
      minMaxInfoErrorPairs,
      testId,
      disableAvatars,
      onGroupMembersLoad,
    },
    ref
  ) => {
    const userGroupSelectorRef = useRef<AntSelect | null>(null);
    const mainContainerRef = useRef<HTMLDivElement | null>(null);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isExpandedPanelOpen, setIsExpandedPanelOpen] = useState(false);

    const styles = useUsersAndGroupsExpandableSelectStyles({
      isFieldDisabled,
      withUnderline,
    });

    const changeDropdownOpenState = useCallback(
      (isOpen: boolean) => {
        setIsDropdownOpen(isOpen);

        if (!isOpen && onBlur) {
          onBlur();
        }

        if (onDropdownOpenChange) {
          onDropdownOpenChange(isOpen);
        }
      },
      [onBlur, onDropdownOpenChange]
    );

    const { avatars } = useSelectOptionAvatars(
      Array.from(selection.users.values()),
      Array.from(selection.groups.values())
    );

    useImperativeHandle(ref, () => {
      return {
        focus: () => {
          changeDropdownOpenState(true);
        },
        blur: () => {
          changeDropdownOpenState(false);
        },
      };
    });

    useEffect(() => {
      if (!isDropdownOpen || !userGroupSelectorRef.current) {
        return;
      }

      userGroupSelectorRef.current.focus();
    }, [isDropdownOpen]);

    // We need to provide scrollable parent function to UsersAndGroupsExpandablePicker as it will fail to catch scrollable container
    // when going from user avatars to isOpen state.
    const { getScrollableParent } = useScrollableParent(mainContainerRef, [
      RECORD_LISTING_WRAPPER,
    ]);

    const selectionCount = selection.groups.size + selection.users.size;

    const dropdownAlign = {
      points: ['tl', 'bl'],
      offset: [0, 4],
      overflow: {
        adjustX: true,
        adjustY: true,
      },
    };

    return (
      <div
        ref={mainContainerRef}
        className={clsx(styles.editUserWrapper, className)}
        data-testid={testId}
      >
        {isDropdownOpen ||
        (selectionCount === 0 && !editModeOptions) ||
        !!disableAvatars ? (
          <UsersGroupsSelector
            ref={userGroupSelectorRef}
            getPopupContainer={trigger =>
              trigger.parentElement ?? document.body
            }
            errors={errors}
            className={styles.optionContent}
            dropdownClassName={styles.mainDropdown}
            disabled={isFieldDisabled}
            value={{
              // value is unused as popover content controls the selected values.
              // we provide empty arrays to fulfill the prop requirement.
              users: [],
              groups: [],
            }}
            externalDropdownControl={{
              isDropdownOpen: isDropdownOpen,
              onDropdownVisibleChange: open => {
                if (isExpandedPanelOpen) {
                  return;
                }

                if (
                  !open &&
                  editModeOptions &&
                  !editModeOptions.onCloseIntent()
                ) {
                  return;
                }

                changeDropdownOpenState(open);

                if (reValidateField && !open) {
                  reValidateField();
                }
              },
            }}
            dropdownAlign={dropdownAlign}
            popoverContent={({ searchText }) => (
              <UsersAndGroupsExpandablePickerContextProvider
                searchFilter={searchText}
                selectedItems={selection}
                setSelectedItems={setSelection}
                limits={limits}
                errors={errors}
                getPopupContainer={getScrollableParent}
                editModeOptions={editModeOptions}
                minMaxInfoErrorPairs={minMaxInfoErrorPairs}
                onGroupMembersLoad={onGroupMembersLoad}
              >
                <UsersAndGroupsExpandablePicker
                  availableItems={options}
                  isLoadingAvailableItems={isLoadingOptions}
                  onExpandedPanelStateChange={setIsExpandedPanelOpen}
                />
              </UsersAndGroupsExpandablePickerContextProvider>
            )}
          />
        ) : (
          <div
            className={styles.avatars}
            data-testid={USERS_AND_GROUPS_EXPANDABLE_SELECT_AVATARS_WRAPPER}
            onClick={() => {
              if (isFieldDisabled) {
                return;
              }

              changeDropdownOpenState(true);
            }}
          >
            {selectionCount > 0 ? (
              <CustomAvatarGroup
                items={avatars}
                disablePopover={!interactableAvatars}
                onClickShowMore={event => event?.stopPropagation()}
              />
            ) : (
              '-'
            )}
          </div>
        )}
      </div>
    );
  }
);
