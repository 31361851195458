import useData from 'hooks/useData';
import { generatePath } from 'react-router-dom';
import { TASKS_DETAILS } from 'utils/endpoints';
import { StageNames, TaskDetails } from 'utils/types/api/tasks.types';
import { useTasksPanelContext } from 'components/SidePanels/TasksPanel/contexts/TasksPanelContext';
import { getSortedUserAvatars } from 'utils/functions/getSortedUserAvatars';

export const useTaskAssignees = () => {
  const { task } = useTasksPanelContext();

  const [taskDetails, { loading, error, fetchData }] = useData<TaskDetails>(
    generatePath(TASKS_DETAILS, { id: task?.id })
  );

  const taskAssignees = taskDetails?.stages.find(
    stage => stage.name === StageNames.Stage1
  )?.assignees;
  const userAssignees = getSortedUserAvatars(taskAssignees?.users ?? []);

  const fetchTaskDetails = async () => {
    await fetchData();
  };

  return {
    loading,
    error,
    userAssignees,
    taskDetails,
    fetchTaskDetails,
  };
};
