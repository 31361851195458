import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonSecondaryOutlined } from 'components/lib/Button';
import { BUTTON_CLOSE_TESTID } from 'utils/testIds';
import { TasksPanelMode } from '../../types';
import { Loader } from 'components/lib/Loader';
import ErrorComponent from 'components/ErrorComponent';
import { useReassignTaskTabs, useTaskAssignees } from './hooks';
import { useTasksPanelContext } from '../../contexts/TasksPanelContext';
import { ReassignTaskHeader } from '../ReassignTaskHeader';
import { useReassignTaskStyles } from './ReassignTask.styles';
import { useBackToTaskList } from '../../hooks';
import { TabPane, Tabs } from 'components/lib/Tabs';
import { ManageTaskUserAssignees } from './components/ManageTaskUserAssignees';

export const ReassignTask = () => {
  const styles = useReassignTaskStyles();

  const { setMode } = useTasksPanelContext();

  const {
    error,
    loading,
    userAssignees,
    taskDetails,
    fetchTaskDetails,
  } = useTaskAssignees();
  const { activeTab, usersTab } = useReassignTaskTabs(userAssignees.length);

  useBackToTaskList();

  if (error) {
    return <ErrorComponent error={error?.status} />;
  }

  return (
    <Loader spinning={loading}>
      <div className={styles.panelWrapper}>
        <ReassignTaskHeader
          taskName={taskDetails?.name ?? '-'}
          recordId={taskDetails?.object_record}
        />

        <Tabs
          className={styles.tabs}
          activeKey={activeTab}
          destroyInactiveTabPane
        >
          <TabPane key={usersTab.key} tab={usersTab.text}>
            <ManageTaskUserAssignees
              userAssignees={userAssignees}
              fetchTaskDetails={fetchTaskDetails}
            />
          </TabPane>
        </Tabs>

        <footer className={styles.footer}>
          <ButtonSecondaryOutlined
            onClick={() => setMode(TasksPanelMode.List)}
            data-testid={BUTTON_CLOSE_TESTID}
          >
            <FormattedMessage id='misc.close' defaultMessage='Close' />
          </ButtonSecondaryOutlined>
        </footer>
      </div>
    </Loader>
  );
};
