import React from 'react';
import { ManageTaskUserAssigneesProps } from './ManageTaskUserAssignees.types';
import { useManageTaskUserAssigneesStyles } from './ManageTaskUserAssignees.styles';
import { useIntl } from 'react-intl';
import List from 'components/List';
import { AvatarItem } from 'components/lib/Avatar/types';
import PeopleListElement from 'pages/Records/RecordsListing/RecordAccessPanel/components/PeopleListElement';
import { StageNames } from 'utils/types/api/tasks.types';
import DisabledButtonTooltip from 'components/DisabledButtonTooltip';
import { ButtonPrimary } from 'components/lib/Button';
import { PlusIcon } from 'components/Icon';
import { ADD_USER_ASSIGNEES_BTN_TESTID } from 'utils/testIds';
import {
  useTaskUserAssigneesLimit,
  useUnassignTaskUserAssignee,
} from './hooks';
import clsx from 'clsx';
import { TasksPanelMode } from 'components/SidePanels/TasksPanel/types';
import { useTasksPanelContext } from 'components/SidePanels/TasksPanel/contexts/TasksPanelContext';
import { LimitAlert } from 'components/LimitAlert';
import { UnassignTaskUserAssigneeModal } from './components/UnassignTaskUserAssigneeModal';

export const ManageTaskUserAssignees = ({
  userAssignees,
  fetchTaskDetails,
}: ManageTaskUserAssigneesProps) => {
  const intl = useIntl();
  const styles = useManageTaskUserAssigneesStyles();

  const { setMode, setStage } = useTasksPanelContext();
  const {
    limit,
    isLimitReached,
    disabledBtnTooltipText,
  } = useTaskUserAssigneesLimit(userAssignees);
  const {
    userToUnassign,
    isUnassigningHimself,
    isUnassigning,
    cancelUnassigning,
    setUserToUnassign,
    unassignUserFromTask,
  } = useUnassignTaskUserAssignee(fetchTaskDetails);

  const handleUserItemDelete = (user: AvatarItem) => {
    const { id, firstName, lastName } = user;

    setUserToUnassign({ id, name: `${firstName} ${lastName}` });
  };

  const openAddUsersView = () => {
    setMode(TasksPanelMode.AddAssignees);
    setStage({
      name: StageNames.Stage1,
      assignees: userAssignees,
      fetchTask: fetchTaskDetails,
    });
  };

  return (
    <div className={styles.contentWrapper}>
      <DisabledButtonTooltip
        title={disabledBtnTooltipText}
        showTooltip={isLimitReached}
      >
        <ButtonPrimary
          className={clsx({ [styles.disabledBtn]: isLimitReached })}
          icon={<PlusIcon size={10} />}
          disabled={isLimitReached}
          data-testid={ADD_USER_ASSIGNEES_BTN_TESTID}
          onClick={openAddUsersView}
        />
      </DisabledButtonTooltip>

      {limit && (
        <LimitAlert
          itemsCount={userAssignees.length}
          itemsLimit={limit}
          errorMessage={intl.formatMessage(
            {
              id: 'tasksPanel.assigneesLimitErrorMessage',
              defaultMessage:
                'Maximum of {limit} {limit, plural, one {assignee} other {assignees}} has been reached.',
            },
            { limit }
          )}
          warningMessage={intl.formatMessage(
            {
              id: 'tasksPanel.assigneesLimitWarningMessage',
              defaultMessage:
                'A stage can have up to {limit} {limit, plural, one {assignee} other {assignees}}. This currently has {current}.',
            },
            { limit, current: userAssignees.length }
          )}
        />
      )}

      <List<AvatarItem>
        className={styles.usersList}
        items={userAssignees}
        emptyDataDescription={intl.formatMessage({
          id: 'misc.noUsersHaveBeenAssigned',
          defaultMessage: 'No users have been assigned',
        })}
        renderItem={item => (
          <PeopleListElement
            {...item}
            onDelete={() => handleUserItemDelete(item)}
          />
        )}
      />

      <UnassignTaskUserAssigneeModal
        userToUnassign={userToUnassign}
        isUnassigningHimself={isUnassigningHimself}
        isLoading={isUnassigning}
        onConfirm={unassignUserFromTask}
        onCancel={cancelUnassigning}
      />
    </div>
  );
};
