import { AvatarItem } from 'components/lib/Avatar/types';
import { useTaskUserAssigneeOptions } from 'components/SidePanels/TasksPanel/hooks/useTaskUserAssigneeOptions';
import { AccountTypeOptions } from 'pages/Users/enums';
import { useIntl } from 'react-intl';

export const useTaskUserAssigneesLimit = (userAssignees: AvatarItem[]) => {
  const intl = useIntl();

  const { options } = useTaskUserAssigneeOptions();

  const isOneTCUserAssigned = userAssignees.some(
    user => user.accountType === AccountTypeOptions.OneTimeCompletion
  );

  const limit = isOneTCUserAssigned
    ? options?.restrictions.limit_assignees_one_time_completion
    : options?.restrictions.limit_assignees_standard;

  const isLimitReached = limit
    ? userAssignees.length >= limit
    : userAssignees.length >= 100;

  const disabledBtnTooltipText = isOneTCUserAssigned
    ? intl.formatMessage({
        id: 'owners.maximumNumberReached1TC',
        defaultMessage:
          'A one-time completion user has been assigned, therefore no further user can be assigned.',
      })
    : intl.formatMessage({
        id: 'owners.maximumNumberReached',
        defaultMessage:
          'The maximum number of assignees has been reached. Remove assignees to add new ones.',
      });

  return {
    limit,
    isLimitReached,
    disabledBtnTooltipText,
  };
};
